import { defineAsyncComponent } from "vue"

let youtube = defineAsyncComponent( () => import("./loader.vue") )
let playList = defineAsyncComponent( () => import("./play-list.vue") )

export let plugin:$pocketsPlugin = {
    createApp(app: any, $pockets: $pockets){
        app.component('youtube', youtube )
        app.component('youtube-playlist', playList )
    },
}
